// server.service.ts

import fetch, {Headers} from 'node-fetch';

// Server location
//const serverurl = 'http://localhost:8080';
//const serverurl = 'http://3.91.79.133'; // Our staging server
//const serverurl = 'http://3.90.31.112'; // One of pra's staging server
//const serverurl = 'http://3.82.45.159'; // One of pra's staging server
const serverurl = 'https://pts.patientrightsadvocatefiles.org'; // PRA's elb

// Path to cloudflare files. Only used in production
const cfdomain = 'storage.patientrightsadvocatefiles.org';



//const serverurl ='http://184.73.26.186:3001'; // New visiblecharges account


export default class ServerService
{
  // Return the domain where cloudflare files can be found. You must add the project and storage
  public getCFDomain() : string {
    return cfdomain;
  }

  public async getResponse(endpoint: string): Promise<any>
  {  
    const url = `${serverurl}/${endpoint}`;
    //console.log('**** calling fetch', endpoint, url);
    const headers = new Headers({
      'sessionid': '5087494111016062868872034' // During development to prevent others from calling our api
    });
    const response = await fetch(url, {
      headers,
    });

    let matches: any[] = await response.json();
    let error: string | null = null;

    // Errors is a special case. One match is returned with an error
    if (matches && matches.length === 1 && 'error' in matches[0]) {
      error = matches[0]['error'];
      matches = [];
    }

    //for(const header of response.headers) {
    //  console.log('Header', header);
    //}
    const sessionid: string | null = response.headers.get('sessionid');
    const requestid: string | null = response.headers.get('requestid');
    //console.log('raw', matches);
    //console.log('sessionid', sessionid);
    //console.log('requestid', requestid);
    //console.log(response.headers);

    // console.log('matches', matches);

    return {
      matches,
      error,
      status: {
        sessionid, 
        requestid
      }
    };
  }

  // Post, without session header
  public async post(endpoint: string, body: any): Promise<any>
  {  
    const url = `${serverurl}/${endpoint}`;
    // console.log('**** calling fetch', endpoint, url);
    const headers = new Headers({
      'content-type': 'application/json'
    });
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body
    });

    const resp: any = await response.json();

    return resp;
  }


  public async getLink(endpoint: string): Promise<any>
  {  
    const url = `${serverurl}/${endpoint}`;
    // console.log('**** calling fetch', endpoint, url);
    const headers = new Headers({
      'sessionid': '5087494111016062868872034' // During development to prevent others from calling our api
    });
    const response = await fetch(url, {
      headers,
    });

    const link: any = await response.json();
    //for(const header of response.headers) {
    //  console.log('Header', header);
    //}
    const sessionid: string | null = response.headers.get('sessionid');
    const requestid: string | null = response.headers.get('requestid');

    return {
      link,
      status: {
        sessionid, 
        requestid
      }
    };
  }

}
